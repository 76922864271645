<template>
<div class="flex px-10 lg:pt-0 pt-2 w-full items-center fixed top-0 right-0 dark:bg-black bg-white mb-10 z-20">
  <div class="w-1/6"><Logo/> </div>
  <div class="w-5/6"><Menu/>  </div>
</div>
</template>
<script>
import { defineComponent } from 'vue';
import Logo from '../Logo.vue';
import Menu from '../Menu.vue';

export default defineComponent({
  components: { Logo, Menu },
  setup() {
    return {};
  },
});
</script>
