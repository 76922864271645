import { createI18n } from 'vue-i18n';

// const defaultLocale = 'en';
// const i18n = createI18n({
//   locale: defaultLocale,
//   messages: {
//     en: {
//       hello: 'Hello',
//     },
//   },
// });
// const langs = [
//   'en',
//   'kh',
// ];
const defaultLang = localStorage.getItem('locale') ?? 'en';
const i18n = createI18n({
  fallbackLocale: defaultLang,
});
async function importLanguage(language = '') {
  const json = await import(/* webpackChunkName: "i18n/[request]" */ `./locales/${language}.json`);
  i18n.global.setLocaleMessage(language, json.default);
}
// async function setI18nLanguage(newLang = defaultLang) {
//   if (i18n.global.locale === newLang) {
//     return;
//   }
//   const hasThisLang = langs.find((lang) => lang === newLang);
//   if (!hasThisLang) {
//     i18n.global.locale = defaultLang;
//     return;
//   }
//   const hasImported = Object.prototype.hasOwnProperty.call(i18n.global.messages, newLang);
//   if (!hasImported) {
//     await importLanguage(newLang);
//   }
//   i18n.global.locale = newLang;
// }
// importLanguage(defaultLang).then(() => {
//   const lang = 'en';
//   setTimeout(() => {
//     if (lang) {
//       setI18nLanguage(lang);
//     } else {
//       setI18nLanguage();
//     }
//   }, 3000);
// });
importLanguage(defaultLang);
export default i18n;
export const { t } = i18n.global;
