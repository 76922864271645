import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faPhone,
  faCircleNodes,
  faHouseLaptop,
  faHandHoldingHeart,
  faChevronUp, faUsers, faBarsProgress,
  faSun, faLocationDot, faEnvelope, faPaperPlane, faInstitution,
  faPersonCane, faCubes, faMartiniGlassCitrus, faClockRotateLeft,
  faBullseye, faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './assets/shared.css';
/* eslint-disable */
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

library.add(faPhone, faPersonCane, faInstitution, faArrowDown, faUsers, faCircleNodes,
  faHouseLaptop, faChevronUp, faCubes, faMartiniGlassCitrus, faClockRotateLeft, faBarsProgress,
  faHandHoldingHeart, faSun, faLocationDot, faEnvelope, faPaperPlane, faBullseye);

Swiper.props.modules = [Navigation];
createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Swiper', Swiper)
  .component('SwiperSlide', SwiperSlide)
  .use(i18n)
  .use(ElementPlus)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .use(VueGtag, {
    config: { id: 'UA-239766832-2' },
  })
  .mount('#app');
