
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import store from '../store';

export default defineComponent({
  name: 'Menu',
  setup() {
    const route = useRoute();
    const selectedYear = computed(() => route.params.year ?? 2023);
    const drawer = ref(false);
    const isDark = ref(store.state.isDark);
    const value = ref(localStorage.getItem('locale') ?? 'en');
    const options = [
      {
        value: 'en',
        label: 'English',
      },
      {
        value: 'kh',
        label: 'ខ្មែរ',
      },
    ];
    const switchLocale = (locale = '') => {
      localStorage.setItem('locale', locale);
      window.location.reload();
    };
    document.body.classList.add(`lang-${localStorage.getItem('locale') ?? 'en'}`);
    const toggleRender = () => {
      if (store.state.isDark) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    };
    toggleRender();
    watch(isDark, () => {
      store.commit('setDarkMode', isDark.value);
      toggleRender();
    });
    const preventEventClick = () => {
      store.commit('setEvent', false);
    };
    return {
      drawer,
      isDark,
      value,
      options,
      switchLocale,
      preventEventClick,
      selectedYear,
    };
  },
});
