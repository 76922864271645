<template>
  <router-link to='/'>
    <div class="lg:w-28 xs:w-16">
      <img class="w-full object-cover" src="@/assets/logo.png" alt="Techbodia..."/>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Logo',
  setup() {
    return {};
  },
});
</script>

<style scoped>

</style>
