import { createStore } from 'vuex';

export const IS_DARK_MODE_KEY = 'isDarkMode';
const localIsDark = localStorage.getItem(IS_DARK_MODE_KEY);

export default createStore({
  state: {
    isDark: localIsDark !== 'false',
    isDetail: false,
  },
  mutations: {
    setDarkMode(state, val) {
      localStorage.setItem(IS_DARK_MODE_KEY, JSON.stringify(val));
      state.isDark = val;
    },
    setEvent(state, detail) {
      state.isDetail = detail;
    },
  },
  actions: {
  },
  modules: {
  },
});
