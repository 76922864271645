<template>
  <div class="container-flaud w-full mx-auto background-footer bg-cover bg-center flex items-center px-8"
    :style="{ backgroundImage: 'url(' + require('@/assets/img/footer-with-dots.png') + ')' }">

    <div class="flex md:gap-10 gap-6 md:items-center justify-start flex-col md:flex-row py-8">
      <div>
        <img src="@/assets/img/Techbodia-white.png" class="md:w-60 w-32" alt="logoFooter"/>
      </div>
      <div class="flex gap-4 flex-col">
        <h4 class="bokor-font text-white text-3xl font-bold">{{$t('contact_us')}}</h4>
        <div class="flex gap-4 flex-col xs:flex-row items-start xs:items-center">
          <el-link :underline="false" href='https://www.facebook.com/techbodia' target='_blank' class="">
            <div class="flex items-center gap-2">
              <img src="@/assets/img/footer/fb.png" class="w-10">
              <span class='text-white'>Techbodia</span>
            </div>
          </el-link>
          <el-link :underline="false" href='https://www.linkedin.com/company/techbodia' target='_blank' class="">
                <div class="flex items-center gap-2">
                  <img src="@/assets/img/footer/in.png" class="w-10">
                  <span class='text-white'>Techbodia</span>
                </div>
              </el-link>
        </div>
        <div class="flex footerIcon items-center">
          <el-link href="mailto:info.hr@techbodia.com" :underline="false" >
            <img src="@/assets/img/footer/email.png" class="w-10">
            <span class='ml-2 text-white'>info.hr@techbodia.com</span>
          </el-link>
        </div>
        <div class='text-white text-sm'>
          {{$t('designed_and_developed_by_techbodia')}}
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';
import backGroundImage from '@/assets/img/footer-bg.png';

export default defineComponent({
  setup() {
    return {
      backGroundImage,
    };
  },
});
</script>
