<template>
  <el-backtop :right="20" :bottom="20">
    <font-awesome-icon icon="chevron-up" class='primary' size='lg'/>
  </el-backtop>
  <div class="min-h-screen flex flex-col">
    <Header/>
    <main class=" mb-auto">
      <router-view />
    </main>
    <Footer/>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Header from './components/layouts/Header.vue';
import Footer from './components/layouts/Footer.vue';

export default defineComponent({
  components: { Header, Footer },
  name: 'App',
  setup() {
    return {};
  },
});
</script>
<style>
:root {
  --font-family: Arial;
  --primary: #FF0079;
  --secondary: #fff;
  --january: #E7BB0C;
  --february: #E99637;
  --march: #FF1584;
  --april:  #CA3041;
  --may: #C70E6D;
  --june: #880F69;
  --july: #4CBD51;
  --august: #5ED4A4;
  --september: #4C9FBD;
  --october:  #4C79BD;
  --november: #4C59BD;
  --december: #774CBD;
}
</style>
