import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/how-we-work',
    name: 'HowWeWork',
    component: () => import(/* webpackChunkName: "WhatWeDo" */ '../views/HowWeWork.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
  },
  {
    path: '/career/',
    name: 'Career',
    redirect: '/career/all',
    children: [
      {
        path: ':type?',
        component: () => import(/* webpackChunkName: "Career" */ '../views/Career.vue'),
      },
    ],
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "Events" */ '../views/Events.vue'),
    children: [
      {
        path: ':year',
        component: () => import(/* webpackChunkName: "EventsComponent[request]" */ '@/components/events/EventsComponent.vue'),
      },
      {
        path: ':year/:month',
        component: () => import(/* webpackChunkName: "EventDetail[request]" */ '@/components/events/EventsDetail.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
